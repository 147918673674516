@use './mixins.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
.almarai-light {
  font-family: "Almarai", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.almarai-regular {
  font-family: "Almarai", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.almarai-bold {
  font-family: "Almarai", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.almarai-extrabold {
  font-family: "Almarai", sans-serif;
  font-weight: 800;
  font-style: normal;
}

:root {
  font-size: 11px;

  @include sm {
    font-size: 12px;
  }

  @include md {
    font-size: 13px;
  }

  @include lg {
    font-size: 14px;
  }

  @include xl {
    font-size: 15px;
  }

  @include xxl {
    font-size: 16px;
  }
}
